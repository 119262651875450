@import (reference) 'styles/app/app.less';
@import (reference) './header.less';
@import (reference) './footer.less';

@modal-min-height: 150px;
@modal-max-height: 660px;
@modal-max-body-height: calc(
	@modal-max-height - @modal-header-height
); // exclude footer height cause its positioned absolutely within the body
@modal-footer-offset: 32px; // due to absolute positioned footer we need to add some offset (16px bottom padding in footer + 16px from footer bottom to end of viewport)

.eitje-modal-2-body {
	max-height: clamp(
		@modal-min-height,
		calc(100vh - @modal-header-height - @modal-footer-offset),
		@modal-max-body-height
	); // clamp calculates based on minimum value, an ideal value, and a maximum value
	overflow-y: auto;
	overflow-x: hidden;
	padding-bottom: calc(
		@modal-footer-height + 1px
	); // footer is positioned absolute and thus taken out of the flow (footer height + 1px for border)

	&-without-footer {
		padding-bottom: 16px;
	}

	.warning-message {
		margin: 8px 24px;
	}

	.ant-spin {
		align-self: center;
	}
}

.eitje-modal-2-body.eitje-modal-2-body-has-left-panel {
	display: flex;

	.side-menu {
		height: 100%;
	}

	> div,
	> form {
		flex-grow: 1 !important;
		overflow-x: hidden;

		.eitje-modal-2-body-has-left-panel {
			flex-grow: unset !important;
		}
	}

	> .eitje-form-2 {
		overflow-y: auto;
	}
}

.eitje-modal-2.set-fixed-height .eitje-modal-2-main {
	@modal-height: calc(var(--modal-height) * 1px);

	.eitje-modal-2-body {
		height: calc(@modal-height - @modal-header-height) !important;

		.eitje-form-2-row:last-child {
			.border(bottom); // reset last border, since the form does not necessarily touch the footer's bottom anymore
		}
	}
}

// Automatically give all modal forms an overflow, so that the optional form message at the top of the form
// will always be visible on scroll.
.eitje-modal-2-body .eitje-form-2 {
	overflow-y: auto;
}
