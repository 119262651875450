@import (reference) 'styles/app/app.less';

.basic-table {
	&-body {
		a {
			display: contents;
		}

		// To have the same border colors as the table
		.eitje-layout-disabled {
			outline-color: @medium-border !important;
			border-color: @medium-border !important;
		}
	}
}