@import (reference) 'styles/app/app.less';

.basic-table-header {
	z-index: 3; // to overlay the hovered table row

	&-cell {
		&.eitje-layout-has-hover:hover {
			.border(@type: outline);
			z-index: 3;
		}

		&:first-child {
			border-top-left-radius: 4px;
		}

		&:last-child {
			border-top-right-radius: 4px;
		}
	}
}