@import (reference) 'styles/app/app.less';
@import (reference) './body.less';
@import (reference) './index.less';

@panel-width: 336px; // replace with prop once we want different widths

/////////////////////////////
// panels container styling (optionally present)
/////////////////////////////

.eitje-modal-2.has-panel .ant-modal-body {
	display: flex;
}

.eitje-modal-2 .eitje-modal-2-main {
	flex-grow: 1;
	min-height: 100%;
	position: relative;
}

/////////////////////////////
// max height styling (different for each position)
/////////////////////////////

// right panel lives next to main (header + body + footer)
.eitje-modal-2 .eitje-modal-2-main + .eitje-modal-2-right-panel {
	max-height: @modal-max-height;
}

// TODO: for lower level panel layouts, write more specified selectors
// ...

/////////////////////////////
// panel styling
/////////////////////////////

.eitje-modal-2-right-panel {
	.border(left);
	min-height: 100%;
	width: @panel-width;
	background: @light-background;
	border-top-right-radius: @modal-border-radius;
	border-bottom-right-radius: @modal-border-radius;
	position: relative;
	overflow-y: auto;
}