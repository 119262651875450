.eitje-rich-text-document-content {
  overflow-y: auto;
  width: 100%;
  scrollbar-color: #aaaaaa transparent;
  scrollbar-width: thin;
}
.eitje-rich-text-document-content .eitje-rich-text-editor-content {
  margin: 1.5rem 0;
}
.eitje-rich-text-document-content .eitje-rich-text-editor-content :first-child {
  margin-top: 0;
}
.eitje-rich-text-document-content .eitje-rich-text-editor-content .ProseMirror-focused {
  outline: none;
}
.eitje-rich-text-document-content .eitje-rich-text-editor-content .tiptap.ProseMirror {
  width: 100%;
}
.eitje-rich-text-document-content .eitje-rich-text-editor-content .ProseMirror-hideselection *::selection {
  color: inherit;
  background: transparent;
}
.eitje-rich-text-document-content .floating-action-menu {
  box-shadow: 0 0 10px 10px #aaaaaa25;
}
.eitje-layout.eitje-layout-color-set .eitje-rich-text-editor-content :not(.button-bar) {
  color: inherit;
}
.eitje-rich-text-editor-content .ProseMirror {
  padding-inline: 33px;
}
.eitje-rich-text-editor-content .drag-handle-menu {
  height: 21px;
  width: 21px;
  align-items: center;
  border-radius: 3px;
  cursor: grab !important;
  display: flex;
  justify-content: center;
}
.eitje-rich-text-editor-content .drag-handle-menu:hover {
  background: #e3e3e3;
}
.eitje-rich-text-editor-content .drag-handle-menu:hover path {
  fill: #000000 !important;
}
.eitje-rich-text-editor-content .drag-handle-menu .eitje-layout-has-hover.eitje-layout-has-hover:hover {
  cursor: grab !important;
}
.eitje-rich-text-editor-content h1,
.eitje-rich-text-editor-content h2,
.eitje-rich-text-editor-content h3,
.eitje-rich-text-editor-content p,
.eitje-rich-text-editor-content span:not(.eitje-icon) {
  text-wrap: pretty;
  white-space: pre-wrap;
  word-break: break-word;
  padding: 3px 0;
  width: 100%;
}
.eitje-rich-text-editor-content h1,
.eitje-rich-text-editor-content h2,
.eitje-rich-text-editor-content h3 {
  font-weight: 600;
}
.eitje-rich-text-editor-content h1 {
  margin: 2rem 0 4px 0;
  font-size: 1.875em;
}
.eitje-rich-text-editor-content h2 {
  margin: 1.4rem 0 1px 0;
  font-size: 1.5em;
}
.eitje-rich-text-editor-content h3 {
  margin: 1rem 0 1px 0;
  font-size: 1.25em;
}
.eitje-rich-text-editor-content p {
  margin: 1px 0;
}
.eitje-rich-text-editor-content span {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.eitje-rich-text-editor-content ul,
.eitje-rich-text-editor-content ol {
  padding: 3px 1rem;
  margin: 0;
}
.eitje-rich-text-editor-content ul li p,
.eitje-rich-text-editor-content ol li p {
  margin: 0.25em 0;
}
.eitje-rich-text-editor-content a {
  color: #0096ff !important;
  cursor: pointer;
  text-decoration: underline;
}
.eitje-rich-text-editor-content a:hover {
  color: #0673f4 !important;
  text-decoration: underline;
}
.eitje-rich-text-editor-content blockquote {
  border-left: 3px solid #0096ff;
  margin: 1.5rem 0;
  padding-left: 1rem;
}
.eitje-rich-text-editor-content blockquote > p {
  padding: 1px 0px;
}
.eitje-rich-text-editor-content mark {
  border-radius: 4px;
  box-decoration-break: clone;
  padding: 0.1rem 0.2rem;
}
.eitje-rich-text-editor-content p:not(table p):not(.column .column p):not(li p):not(blockquote p).is-empty.has-focus::before {
  color: #828282;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] {
  list-style: none;
  margin-left: 0;
  padding: 0;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] li {
  align-items: flex-start;
  display: flex;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-top: 6px !important;
  margin-right: 0.5rem;
  user-select: none;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid #000000;
  border-radius: 3px;
  background-color: transparent;
  transition: background-color 0.3s, border-color 0.3s;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] input[type="checkbox"]::before {
  content: "";
  display: block;
  margin-top: 2px;
  width: 14px;
  height: 14px;
  clip-path: polygon(10% 50%, 40% 80%, 100% 10%, 90% 0%, 40% 60%, 20% 40%, 0% 60%);
  background-color: #ffffff;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] input[type="checkbox"]:checked {
  border-color: #0096ff;
  background-color: #0096ff;
}
.eitje-rich-text-editor-content ul[data-type="taskList"] input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.eitje-rich-text-editor-content ul[data-type="taskList"] ul[data-type="taskList"] {
  margin: 0;
}
