@import 'styles/app/app.less';

body:has(.page-layout) {
	overflow: hidden;

	.page-layout:not(.floating) {
		.page-body {
			overflow: auto !important; // We need to overwrite the default shorthand from Layout "hidden auto" because react-lazyload only accepts "auto" or "scroll"
		}
	}

	.page-layout.floating {
		overflow-y: auto;

		.page-headers {
			position: sticky;
			top: 0px;
			z-index: 10;
			background-color: @light-background;
		}

		.page-footers {
			position: sticky;
			inset: 0;
			z-index: 900; // Make sure the footers are above everything, except popouts
		}
	}

	.page-layout:not(.unset-page-padding) .page-body > *:last-child {
		&:not(.advanced-table) {
			// Skip advanced-table, since this creates an awkward scroll for the right floating ListPicker to show more column.
			.var(body-bottom-padding);
			// By default we display a space of 48px at the bottom of the body, to make it clear to a user that he has reached the bottom
			// We made this a variable in case a different padding (or 0px) is needed

			padding-bottom: @var-value;
		}
	}
}