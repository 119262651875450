@import (reference) 'styles/app/app.less';
.var(page-top-offset);
@settings-header-height: 56px;

.setting-container {
	.row;
	.align-items-center;
	.vertical-padding(4px);
	height: 44px;
	width: 100%;

	.setting-container-text-container {
		flex: 1;
	}

	.setting-container-label {
		font-size: 12px;
		flex: 1;
	}

	.eitje-table-header-sticky {
		top: @var-value;
		z-index: 999;
	}

	.setting-container-input {
		display: flex;
		align-items: center;
		width: 220px;
		justify-content: flex-end;

		button:not(.ant-switch) {
			width: 100%;
		}
		:is(a.modalLink) {
			width: 100%;
		}
	}
}

.settings-save-button {
	.var(page-top-offset);
	position: fixed;
	top: calc(@var-value + 8px);
	right: 24px;
	z-index: 999;
}

#settings-page {
	.leftContainer {
		width: 250px;
		background-color: #fff;
		border-left: 1px solid #eee;
		position: fixed;
		left: 0px;
		top: 0;
		bottom: 0;
		top: calc(@settings-header-height + @var-value);
		overflow: scroll;
		border-right: 1px solid #eee;
		z-index: 40;
	}

	.eitje-table-header-wrapper {
		top: 80px !important;
	}
}

.silent-mode-wrapper .setting-container {
	height: unset;
}
