@import (reference) 'styles/app/app.less';

@margin: 24px;
@padding: 16px;
@notif-width: 500px;

// The z-index of this class is handled in z_indices.less
.notifications-container,
.center-notifications {
	position: fixed !important;
	max-height: calc(100vh - @margin);
	flex-direction: column-reverse !important; // We want to display the latest notif on top
	padding-right: @padding;
	top: 70px;
	pointer-events: none;

	> * {
		pointer-events: all;
	}
}

.notifications-container {
	a {
		color: @medium-blue !important;
	}
	right: 0;
	width: calc(@notif-width + @margin + @padding);
}

.center-notifications {
	left: 50%;
	transform: translateX(-50%);

	.notification {
		margin: 0 auto;
	}
}
