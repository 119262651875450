@import (reference) 'styles/app/app.less';

.scroll-to-top {
	.var(page-top-offset);
	@extra-margin: 12px;
	top: calc(@var-value + @extra-margin);
	// page-top-offset is undefined, can't use the variable because the PageLayout, which sets the var,
	// lives lower down the tree.
	display: none;
}

.news-filter-container {
	.var(page-top-offset);
	.box-with-border-and-radius;
	left: calc(50% - 557px);
	top: calc(24px + @var-value);
	position: fixed;
	z-index: 99;
	width: 220px;
	max-height: calc(100vh - 196px);
	overflow-y: auto;
}

.news-filter-container .news-filter-title {
	.border(bottom);
	.bold;
	.increased-line-height;
	padding: 12px 16px;
}
.news-posts-container {
	min-width: 680px;
	max-width: 680px;
	min-height: 800px;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	border-top: 0px;
	border-bottom: 0px;
	padding: 16px;
	padding-top: 24px;
}

.error {
	.eitje-button.team-button {
		border-color: @medium-red;
		p {
			color: @medium-red;
		}

		svg path {
			fill: @medium-red !important;
		}
	}
}

// Nesting required due to very nested settings in some dynamic (:not) property
.news-posts-container .eitje-form .post-form {
	border: none;
}

.news-post-container {
	.box-with-border-and-padding(24px, 24px);
	padding-bottom: 0px;
	border: none;
	.border-radius(large);
	margin-bottom: 24px;

	.news-post-content {
		white-space: break-spaces;
		word-break: break-word;
		font-size: 14px;
		line-height: 1.7;
	}

	.news-post-image {
		.border;
		.border-radius;
		width: 100%;
		object-fit: cover;
		min-height: 320px;
		max-height: 500px;
		border-radius: 6px;
	}
	.eitje-form {
		.eitje-emoji-input-container {
			.flex-field-1;
			.text-input-container;
			position: relative;

			.emoji-mart {
				position: absolute;
				top: 51px;
				right: 0px;
				z-index: 2;
			}

			.elementContainer {
				width: 100%;
				.form-container-right {
					display: flex;
					position: relative;
					.emoInputHolder {
						margin-top: 2px;
						margin-right: 4px;
					}
				}
			}
		}
	}
}

.news-poll-container {
	padding-top: 24px;

	.news-poll-text-container {
		margin-bottom: 24px;
		.news-poll-title {
			.eitje-heading(24px);
			.bold;
		}
	}

	.poll-answer-row {
		.box-with-border-and-padding(12px, 16px);
		.border-radius(large);
		margin-bottom: 4px;
		.row-with-justify-center;
		.hover-effect;

		.poll-answer-text-container {
			.row;
		}

		&.selected {
			justify-content: space-between;
			&:hover {
				background-color: @white;
			}
		}

		&.selected-heavy {
			background-color: @light-blue;
			&:hover {
				background-color: @light-blue;
			}
			.poll-answer-text {
				.bold;
			}
		}
	}
}

.news-comment-container {
	.row-with-justify-start;
	padding-left: 24px;
	padding-bottom: 12px;

	.news-comment-text-container {
		.border-radius(large);
		background-color: @medium-background;
		padding: 2px 4px;
		max-width: 552px;

		p {
			line-height: 1.5;
		}
	}
}

.news-asset-icon {
	.border;
	.border-radius;
	.transition;
	.hover-effect;

	.news-asset-icon-img {
		width: 40px;
		height: 40px;
		padding: 12px;
		opacity: 0.4;
		.transition;
	}

	&:hover {
		.news-asset-icon-img {
			opacity: 1;
		}
	}
}

.news-dashboard-details-container {
	.column;
	justify-content: center;
	align-items: center;
	margin-top: 56px;
	padding-right: 12px;
	padding-left: 12px;
}

.news-dashboard-list-container {
	margin-top: 32px;
	.horizontal-padding(12px);
	.news-dashboard-list-header {
		.bold;
		text-align: center;
		margin-bottom: 12px;
	}
}

.news-dashboard-list-item {
	.row-with-border-and-padding(12px, 8px);
	.border-radius(large);
	margin-bottom: 4px;
	gap: 12px;

	.news-dashboard-list-text-container {
		p {
			line-height: 1.3;
		}
	}

	.event-icon {
		.small-shadow;
		.square-size(30px);
		.border-radius(round);
		border: 2px solid @white;
	}
}

.news-manual-multi-item-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	min-height: 320px;
	height: 320px;
	max-height: 320px;
	width: 100%;
	margin-bottom: 48px;
	margin-top: 4px;

	.unpublished {
		opacity: 0.6;
	}

	.news-manual-item {
		background-color: white;
		box-shadow: 0px 5px 15px 0px ~ '@{medium-grey}25' !important;
		.border-radius(large);
		border: 1px solid @medium-border;
		width: 294px;
		height: 320px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		img {
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			height: 250px;
			width: 292px;
			object-fit: cover;
			margin-bottom: 8px;
		}
	}
}

.post-modal-options-container {
	position: absolute;
	top: 22px;
	right: 12px;
}

.post-asset-container {
	display: flex;
	min-height: 54px;
	align-items: center;
	justify-content: space-between;
	padding: 6px 12px;
	box-shadow: 0px 5px 15px 0px ~ '@{medium-grey}25';
	.border-radius(large);
	border: 1px solid ~ '@{medium-border}90';
	width: 100%;
	margin-bottom: 8px;
}

.news-very-large-padding-top {
	padding-top: 56px;
}

.news-very-large-padding-top .max-list-container,
.review-trade-modal {
	.avatarPanelOpen {
		margin: 0 12px 0 0;
	}
}

.news-very-large-padding-top .planning-trade-status-no-border {
	.border;
	.border-radius;
	width: 100%;
	min-height: 88px;
	margin: 0 0 4px 0;
	height: unset;

	.fRow {
		gap: 12px;
	}
}

.news-comment-container {
	h6 {
		color: @dark-grey;
		padding-top: 5px;
	}
}

.vertical-padding-xs {
	padding: 0 8px;
}

.vertical-padding-s {
	padding: 0 12px;
}

.newspage-information-column {
	.switch-management-personal-info {
		margin: 24px 12px 12px 12px;
	}

	overflow: scroll;
}

.saved-and-planned-box {
	.row-with-border;
	.border-radius(large);
	border: none;
}

.news-comment-container {
	.row;
	justify-content: space-between;
	padding: 0 0 12px 16px;

	.left-comment-column {
		.eitje-avatar-overlay-sm {
			display: inline-block;
			vertical-align: top;
		}

		.news-comment-and-timestamp {
			display: inline-block;
			max-width: 480px;

			.news-comment-text-container {
				padding: 2px 8px;
			}
		}
	}
}