@import (reference) 'styles/app/app.less';

.approve-leave-modal {
	.eitje-modal-2-main {
		display: flex;
		flex-direction: column;

		.ant-spin-nested-loading {
			flex: 1;

			.ant-spin-container {
				height: 100% !important;
			}
		}
	}

	.eitje-modal-2-right-panel {
		min-width: 360px;
	}
}