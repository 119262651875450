@import (reference) 'styles/app/app.less';

.message {
	a {
		color: @medium-blue !important;
	}
	text-align: center;
	align-self: stretch;

	* > {
		// When for example lokalise adds a span for markup purposes
		max-width: 100%;
	}
}
