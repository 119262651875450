@import (reference) 'styles/app/app.less';

.basic-table {
	&-row,
	&-row *,
	&-row-cell,
	&-row-cell * {
		// Forcefully set the weight of all texts, since different Components, Text instances and HTML structures are used for
		// all different cells.
		font-weight: 300 !important;
	}

	&-row {
		.border(@type: outline);
		position: relative;
		user-select: none;

		&.eitje-layout-has-hover:hover {
			z-index: 2; // to have the outline overlay the next sibling
		}

		&-cell {
			&-with-borders {
				.border(right);
			}

			&:last-child {
				.unset-border(right);
			}
		}

		.popout-card {
			width: 100%;
		}
	}

	&-content-height {
		.basic-table-row:last-child {
			border-radius: 0 0 4px 4px;
		}
	}
}